<script setup lang="ts">
definePageMeta({
  layout: 'auth',
  title: 'Login',
})
const { signIn } = useAuth()
const route = useRoute()
const { t } = useI18n()
const { updatePermissions } = usePermissions()
const { errorToast } = useToast()

function viewPasswordHandler(node, e) {
  node.props.suffixIcon = node.props.suffixIcon === 'eye' ? 'eyeClosed' : 'eye'
  node.props.type = node.props.type === 'password' ? 'text' : 'password'
}

const redirect = ref('')
const userId2fa = ref('')

async function loginHandler(credentials: { username: string, password: string }) {
  try {
    const response = await signIn('credentials', { ...credentials, redirect: false })
    if (response.error) {
      const data = JSON.parse(response.error)
      if (data?.api_code === 'two_factor_required')
        return userId2fa.value = data?.data?.user
    }
  }
  catch (err) {
    return errorToast(t('auth.invalid-credentials'))
  }
  updatePermissions()
  navigateTo(redirect.value)
}

const config = useRuntimeConfig()
const apiUrl = config.public.apiUrl

async function handleOtp(formData: { passcode: string }) {
  try {
    const res: GenericObjectI = await $fetch(`${apiUrl}/2fa`, {
      body: { sms_code: formData.passcode, user: userId2fa.value },
      method: 'POST',
    })
    const response = await signIn('refresh', {
      accessToken: res?.data?.token,
      refreshToken: res?.data?.refreshToken,
      redirect: false,
    })
    if (response.error)
      errorToast(t('notify.error.other'))
  }
  catch (error) {
    return errorToast(t('auth.wrong-passcode'))
  }
  updatePermissions()
  navigateTo(redirect.value)
}

onMounted(() => {
  redirect.value = (Array.isArray(route.query?.redirect) ? route.query?.redirect[0] : route.query?.redirect) ?? '/secure'
})
</script>

<template>
  <div>
    <div class="text-center mb-12">
      <h2
        class="text-center text-3xl font-bold text-gray-700 dark:text-white"
      >
        {{ $t('auth.login') }}
      </h2>

      <p
        class="mt-4 text-gray-500 dark:text-gray-300"
      >
        {{
          `${!userId2fa ? $t('auth.welcome-msg') : $t("auth.enter-verification", { entity: $t("shared.form.mobile") })}`
        }}
      </p>
    </div>

    <FormKit
      v-if="!userId2fa"
      id="login"
      type="form"
      :actions="false"
      :incomplete-message="false"
      @submit="loginHandler"
    >
      <div class="mb-6">
        <FormKit
          type="text"
          :label="t('shared.form.email')"
          name="username"
          autocomplete="username"
          placeholder="example@company.com"
          validation="required"
          input-class="!py-4 !text-2xl"
        />
      </div>
      <FormKit
        type="password"
        name="password"
        :label="t('auth.password.title')"
        placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
        autocomplete="current-password"
        validation="required|length:3"
        suffix-icon="eyeClosed"
        suffix-icon-class="text-2xl pl-2 !w-12"
        input-class="!py-4 !text-2xl"
        @suffix-icon-click="viewPasswordHandler"
      />
      <div class="w-full flex justify-end mt-8">
        <NuxtLink
          to="/forgot"
          class="text-primary font-semibold dark:text-gray-300"
        >
          {{ $t('auth.forgot-password') }}
        </NuxtLink>
      </div>
      <div class="w-full mt-5">
        <FormKit
          type="submit"
          :label="$t('auth.login')"
          input-class="w-full !py-3"
        />
      </div>
    </FormKit>
    <FormKit
      v-else
      id="twofactor"
      type="form"
      :actions="false"
      :incomplete-message="false"
      @submit="handleOtp"
    >
      <FormKit
        type="otp"
        name="passcode"
        inner-class="flex justify-center"
        :autofocus="true"
        messages-class="text-center mt-3"
        validation="required"
      />
      <FormKit
        type="submit"
        :label="$t('shared.actions.submit')"
        input-class="w-full !py-3 mt-7"
      />
      <pv-button
        text
        :pt="{ root: 'w-full mt-3' }"
        :pt-options="{ mergeProps: true }"
        :label="t('auth.login-return')"
        @click="userId2fa = ''"
      />
    </FormKit>
  </div>
</template>
